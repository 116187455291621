import React from 'react';
import { graphql } from 'gatsby';

import { Row, Col } from 'styled-bootstrap-grid';
import ContentContainer from '../components/global/contentContainer';

import Helmet from 'react-helmet';

import TutorBio from '../components/tutors/tutorBio';
import Title from '../components/text/title';
import Gallery from '../components/images/gallery';
import TextboxWithHeading from '../components/text/textboxWithHeading';
import { Line } from '../components/utility/line';
import LocationCard from '../components/location/locationCard';

import styled from 'styled-components';

const Theme = {
  highlightColor: '#7fbea0',
  textColor: '#000000',
};

const ContainerTheme = {
  backgroundColor: '#d2f5e6',
};

const LocationContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 18px;
  padding-left: 18px;
`;

const AllLocations = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -18px;
  margin-left: -18px;

  & > :nth-child(6n + 1) {
    flex: 0 0 66.666666%;
    max-width: 66.666666%;
  }

  & > :nth-child(2n) {
    flex: 0 0 25%;
    max-width: 25%;

    margin-left: 8.33%;
  }

  & > :nth-child(3n) {
    flex: 0 0 42%;
    max-width: 42%;

    margin-left: 58%;
  }

  & > :nth-child(4n) {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
    margin-left: 0;
  }

  & > :nth-child(5n) {
    flex: 0 0 25%;
    max-width: 25%;
    margin-left: 0;
  }

  & > :nth-child(6n) {
    flex: 0 0 50%;
    max-width: 50%;

    margin-left: 25%;
  }

  @media (max-width: 767px) {
    & > :nth-child(6n + 1),
    & > :nth-child(2n),
    & > :nth-child(3n),
    & > :nth-child(4n),
    & > :nth-child(5n),
    & > :nth-child(6n) {
      flex: 0 0 50%;
      max-width: 50%;

      margin-left: 0;
    }
  }
`;

const TutorGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const About = ({ data, location }) => {
  const allActiveLocations = Array.from(
    new Set(
      data.allPrismicEvent.edges
        .filter(
          location =>
            location.node.data.location !== undefined &&
            location.node.data.location !== null &&
            location.node.data.location.uid !== null
        )
        .map(location => {
          return location.node.data.location.uid;
        })
    )
  );

  // if (allActiveTutors.includes(tutor.tutor.document.uid)) {
  //   return tutor;
  // }
  const coreTeam = data.prismicAbout.data.select_core_team
    .filter((tutor, index) => tutor.tutor.document !== null)
    .map((tutor, index) => {
      return (
        <TutorBio
          key={index}
          link={tutor.tutor.document.uid}
          details={tutor.tutor.document.data}
        />
      );
    });

  const guestTutors = data.prismicAbout.data.select_guest_tutors
    .filter((tutor, index) => tutor.tutor.document !== null)
    // .filter((tutor, index) => {
    //   if (allActiveTutors.includes(tutor.tutor.document.uid)) {
    //     return tutor;
    //   }
    // })
    .map((tutor, index) => {
      return (
        <TutorBio
          key={index}
          link={tutor.tutor.document.uid}
          details={tutor.tutor.document.data}
        />
      );
    });

  const locations = data.allPrismicLocation.edges
    .filter((location, index) => {
      if (allActiveLocations.includes(location.node.uid)) {
        return location;
      }
    })
    .map((location, index) => {
      return (
        <LocationContainer key={index}>
          <LocationCard
            link={'/about/location/' + location.node.uid}
            details={location.node.data}
          />
        </LocationContainer>
      );
    });

  return (
    <>
      <Helmet>
        <title>{data.prismicAbout.data.title.text}</title>
        <meta name="title" content={data.prismicAbout.data.title.text} />
        <meta name="description" content={data.prismicAbout.data.text.text} />
        <meta
          property="og:url"
          content={'https://www.draw-brighton.co.uk' + location.pathname}
        />
        <meta
          property="og:image"
          content={data.prismicAbout.data.gallery[0].gallery_image.url}
        />
        <meta
          property=" og:image:secure_url"
          content={data.prismicAbout.data.gallery[0].gallery_image.url}
        />
        <meta
          property="og:description"
          content={data.prismicAbout.data.text.text}
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="en" />
        <meta
          name="twitter:title"
          content={data.prismicAbout.data.title.text}
        />
        <meta
          name="twitter:description"
          content={data.prismicAbout.data.text.text}
        />
        <meta name="twitter:card" content="summary_large_image" />

        <meta
          name="twitter:image"
          content={data.prismicAbout.data.gallery[0].gallery_image.url}
        />
      </Helmet>

      <Row css={ContainerTheme} justifyContent="center">
        <Col col={12} md={10} lg={9}>
          <ContentContainer padding={'0 36px'}>
            <Title
              margin={'108px 0'}
              text={data.prismicAbout.data.text.text}
              case="inherit"
            />
          </ContentContainer>
        </Col>

        <Gallery
          margin={'0 0 54px 0'}
          images={data.prismicAbout.data.gallery}
        />

        <ContentContainer
          padding={'0 36px'}
          mobilePadding={'40px 9px 20px 9px'}
        >
          <div id="draw" />
          <TextboxWithHeading
            heading="ABOUT"
            text={data.prismicAbout.data.about_text.html}
            padding={'0 0 36px 0'}
          />

          <div id="work-with-us" />
          <TextboxWithHeading
            heading="WORK WITH US"
            text={data.prismicAbout.data.work_with_us.html}
            padding={'0 0 36px 0'}
          />

          <div id="the-team" />
          <TextboxWithHeading
            heading="CORE TEAM"
            text={data.prismicAbout.data.core_team.html}
          />
        </ContentContainer>

        <Col col={12}>
          <ContentContainer padding={'0 22.5px'} mobilePadding={'0 0 13px'}>
            <TutorGrid>{coreTeam}</TutorGrid>
          </ContentContainer>
        </Col>

        <Col col={12}>
          <Line
            margin={'0 36px 54px 36px'}
            mobileMargin={'0 9px'}
            color={Theme.highlightColor}
          />
        </Col>

        <ContentContainer
          padding={'0 36px'}
          mobilePadding={'40px 9px 20px 9px'}
        >
          <TextboxWithHeading heading="GUEST TUTORS" padding={'0 0 54px 0'} />
        </ContentContainer>

        <Col col={12}>
          <ContentContainer padding={'0 22.5px'} mobilePadding={'0 0 13px 0'}>
            <TutorGrid>{guestTutors}</TutorGrid>
          </ContentContainer>
        </Col>

        <Gallery
          margin={'0 0 54px 0'}
          images={data.prismicAbout.data.additional_gallery}
        />

        <div id="locations" />
        <ContentContainer
          padding={'0 36px'}
          mobilePadding={'40px 9px 20px 9px'}
        >
          <TextboxWithHeading
            heading="LOCATIONS"
            text={data.prismicAbout.data.locations_text.html}
            padding={'0 0 54px 0'}
          />
        </ContentContainer>

        <Col col={12}>
          <Line
            margin={'0 36px 54px 36px'}
            mobileMargin={'0 9px'}
            color={Theme.highlightColor}
          />
        </Col>

        <Col col={12}>
          <ContentContainer padding={'0 36px'} mobilePadding={'40px 9px 12px'}>
            <AllLocations>{locations}</AllLocations>
          </ContentContainer>
        </Col>
      </Row>
    </>
  );
};

export const query = graphql`
  query AboutQuery {
    prismicAbout {
      data {
        title {
          text
        }
        text {
          text
        }
        about_text {
          html
        }
        core_team {
          html
        }
        gallery {
          gallery_image {
            url
            copyright
            dimensions {
              width
              height
            }
          }
        }
        select_core_team {
          tutor {
            document {
              ... on PrismicTutors {
                uid
                data {
                  name {
                    text
                  }
                  image {
                    url
                  }
                }
              }
            }
          }
        }
        select_guest_tutors {
          tutor {
            document {
              ... on PrismicTutors {
                uid
                data {
                  name {
                    text
                  }
                  image {
                    url
                  }
                }
              }
            }
          }
        }
        additional_gallery {
          gallery_image {
            copyright
            dimensions {
              width
              height
            }
            url
          }
        }
        locations_text {
          html
        }
        work_with_us {
          html
        }
      }
    }
    allPrismicLocation {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            subtitle {
              text
            }
            image {
              dimensions {
                width
                height
              }
              url
              copyright
            }
          }
        }
      }
    }
    allPrismicEvent(
      filter: { data: { date: { gte: "2023-07-24" } } }
      sort: { order: ASC, fields: [data___date, data___start_time] }
    ) {
      edges {
        node {
          data {
            tutor {
              uid
            }
            location {
              uid
            }
          }
        }
      }
    }
  }
`;

export default About;
